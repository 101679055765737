@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

.App {
    text-align: center;
    white-space: pre-line;
    // background-color: #ecebfb;
}

//main color theme
$primary: #1f7c4b;
$primary-light: #aeedcc;
$primary-invert: findColorInvert($primary);

//supporting color schemes like buttons
$button-green: #00c65f;
$button-green-invert: findColorInvert($button-green);
$primary-title-header: #b0afb0;

$card-green: #80e3ae;
$card-green-invert: findColorInvert($card-green);
$primary-title-header-invert: findColorInvert($primary-title-header);

.box-changes-color-on-hover:hover {
    background-color: #1f7c4b;
    color: white;
}

.tile-has-green-border {
    border-color: #1f7c4b;
}

$custom-colors: (
    "button-green": ($button-green, $button-green-invert),
    "card-green": ($card-green, $card-green-invert),
    "primary-title-header": ($primary-title-header, $primary-title-header-invert)
);

.landing-page-title {
    font-size: 65px;
    font-weight: bold;
    line-height: 70px;
}

@import '../../node_modules/bulma/bulma.sass', '../../node_modules/bulma-extensions/bulma-calendar/src/sass/index.sass';
